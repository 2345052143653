<template>
  <b-sidebar
    id="assign-role-sidebar"
    :visible="isAssignRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-assign-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="dataUser.email"
                readonly
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Role"
            rules="required"
          >
            <b-form-group
              label="Role"
              label-for="role"
            >
              <v-select
                v-model="userData.role"
                :options="optionsRole"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Assign
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAssignRoleSidebarActive',
    event: 'update:is-assign-role-sidebar-active',
  },
  props: {
    isAssignRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const blankData = {
      role: null,
    }
    const selectedRole = ref(null)
    const optionsRole = ref([])
    const fetchRole = () => {
      const params = {
        take: 50,
        page: 1,
      }
      store.dispatch('roleManager/fetchRoleManager', params).then(res => {
        console.log(res)
        optionsRole.value = res.body.data.map(x => ({
          label: x.name,
          value: x.id,
        }))
      }).catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: e.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const userData = ref(JSON.parse(JSON.stringify(blankData)))
    const onSubmit = async () => {
      console.log(userData.value.role.value)
      const payload = {
        roleId: userData.value.role.value,
      }
      store.dispatch('users/assignRole', { id: props.dataUser.id, role: payload })
        .then(() => {
          emit('refetch-data')
          emit('update:is-assign-role-sidebar-active', false)
        }).catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    fetchRole()
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      toast,
      userData,
      refFormObserver,
      getValidationState,
      resetForm,
      optionsRole,
      selectedRole,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#assign-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
